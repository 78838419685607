<template>
  <div class="bg-white gift-code-container">
    <b-card-title class="title-content">
      {{ $t('profile.giftCodeTab.textRedeemGiftCode') }}
    </b-card-title>

    <b-card-text
      v-if="totalGiftCode >= 2"
      class="warning-message d-flex align-items-center"
    >
      <img
        class="alert-img mr-50"
        alt="alert"
        :src="require('@/assets/images/common/ic-alert.svg')"
      >
      {{ $t('profile.giftCodeTab.contentYouAreOnlyAble') }}
    </b-card-text>

    <div
      v-if="isNotAvailableGiftCode"
      class="not-available-gift"
    >
      <div>
        <b-img
          fluid
          alt="img"
          class="logo-img"
          :src="require('@/assets/images/pages/giftCode/ic-no-gift-code.svg')"
        />
      </div>
      {{ $t('profile.giftCodeTab.contentGiftCodeHasExpired') }}
    </div>
    <div
      v-else
    >
      <div
        v-for="(giftCode, index) in listGiftCode"
        :key="index"
        class="gift-code-details-container"
      >
        <div
          class="gift-code-details"
        >
          <div class="left-detail">
            <div class="left-detail__top">
              <div :class="isPaidGiftCode(giftCode) ? 'gift-card-paid' : 'gift-card'">
                <div class="left-voucher" />
                <div class="right-voucher">
                  <p class="m-0">
                    {{ amountFormat(giftCode.value) }}
                  </p>
                </div>
              </div>
              <div class="info-gift-code">
                <b-card-title class="m-0 title-info">
                  {{ $t('profile.giftCodeTab.contentAmountGetWhenSpend', {amountGift: amountFormat(giftCode.value), minimumSpending: amountFormat(giftCode.minimumSpending)}) }}
                </b-card-title>
                <div class="sub-info-gift-code">
                  <div
                    v-if="!isPaidGiftCode(giftCode)"
                    class="info-limit"
                  >
                    <b-card-text
                      class="content-info"
                    >
                      {{ $t('profile.giftCodeTab.textLimitedQuantity') }}
                    </b-card-text>
                  </div>
                  <b-card-text class="content-expired">
                    {{ $t('payment.creditCard.labelExpired') }}: {{ expiredDate(giftCode.endedAt) }}
                  </b-card-text>
                </div>
              </div>
            </div>
            <div
              v-if="isSavedGiftCode(giftCode)"
              id="loader"
              class="left-detail__bottom"
            >
              <div
                id="countText"
                class="percent-count"
              >
                <p class="title-percent">
                  {{ $t('profile.giftCodeTab.textMinimumRequiredSpending') }}:
                </p>
                <p class="mb-0">
                  {{ amountFormat(currentSpending(giftCode)) }}/{{ amountFormat(minimumSpending(giftCode)) }}
                </p>
              </div>
              <div class="progress-bar">
                <div
                  id="progress"
                  class="progress"
                  :style="{ width: `${progressPercent(giftCode)}%` }"
                />
              </div>
            </div>
            <div
              v-else-if="isPaidGiftCode(giftCode)"
              id="loader"
              class="left-detail__bottom"
            >
              <div
                id="countText"
                class="percent-count"
              >
                <p class="title-percent">
                  {{ $t('profile.giftCodeTab.textMinimumRequiredSpending') }}:
                </p>
                <p class="mb-0 content-percent-paid">
                  {{ $t('profile.giftCodeTab.textReachYourTarget') }}
                </p>
              </div>
              <div class="progress-bar">
                <div
                  id="paid"
                  class="paid"
                />
              </div>
            </div>
            <div
              v-else
              id="loader"
              class="left-detail__bottom"
            >
              <div
                id="countText"
                class="percent-count"
              >
                <p class="title-percent">
                  {{ $t('profile.giftCodeTab.contentYouMustReachAMinimum', {minSpend: amountFormat(giftCode.minSpend)}) }}
                </p>
              </div>
            </div>
          </div>
          <div class="right-detail">
            <div
              v-if="isSavedGiftCode(giftCode)"
              class="btn-saved-gift"
            >
              <b-img
                fluid
                alt="img"
                class="logo-img"
                :src="require('@/assets/images/pages/giftCode/ic-saved-gift.svg')"
              />
              <b-card-title class="__content">
                {{ $t('profile.giftCodeTab.btnSave') }}
              </b-card-title>
              <b-card-text
                :key="timerCount"
                class="m-0"
              >
                {{ dayLeft(giftCode) }}
              </b-card-text>
            </div>
            <div
              v-else-if="isPaidGiftCode(giftCode)"
              class="btn-paid-gift"
            >
              <b-img
                fluid
                alt="img"
                class="logo-img"
                :src="require('@/assets/images/pages/giftCode/ic-paid.svg')"
              />
              <b-card-text
                :key="timerCount"
                class="m-0"
              >
                {{ $t('profile.giftCodeTab.btnPaid') }}
              </b-card-text>
            </div>
            <div
              v-else-if="isFullRedeemed(giftCode)"
              class="btn-full-redeemed"
            >
              <b-img
                fluid
                alt="img"
                class="logo-img"
                :src="require('@/assets/images/pages/giftCode/ic-save-gift.svg')"
              />
              <b-card-text class="m-0">
                {{ $t('profile.giftCodeTab.btnSaveThisGift') }}
              </b-card-text>
              <b-card-text class="m-0 font-12 text-red">
                {{ $t('profile.giftCodeTab.textFullyRedeemed') }}
              </b-card-text>
            </div>
            <div
              v-else
              class="btn-save-gift"
              @click="handleSaveGift(giftCode)"
            >
              <b-img
                fluid
                alt="img"
                class="logo-img"
                :src="require('@/assets/images/pages/giftCode/ic-save-gift.svg')"
              />
              <b-card-text class="m-0">
                {{ $t('profile.giftCodeTab.btnSaveThisGift') }}
              </b-card-text>
            </div>
          </div>
        </div>
        <b-collapse
          :id="`collapse-${ index }`"
          class="collapse-gift-code"
        >
          <b-card class="mb-0 collapse-item">
            <div class="info-detail-gift-code">
              <div>
                <ul class="d-flex justify-content-between">
                  <li>
                    <b-card-title class="title-info">
                      {{ $t('profile.giftCodeTab.textRedeemCode') }}
                    </b-card-title>
                    <b-card-text class="content-info">
                      {{ giftCode.code }}
                    </b-card-text>
                  </li>
                  <li>
                    <b-card-title class="title-info">
                      {{ $t('profile.giftCodeTab.textAvailableQuantity') }}
                    </b-card-title>
                    <b-card-text class="content-info">
                      {{ availableQuantity(giftCode) }}
                    </b-card-text>
                  </li>
                  <li>
                    <b-card-title class="title-info">
                      {{ $t('profile.giftCodeTab.textWorthGift') }}
                    </b-card-title>
                    <b-card-text
                      class="content-info value-gift-code"
                      :class="isPaidGiftCode(giftCode) ? 'value-gift-code-paid' : 'value-gift-code'"
                    >
                      {{ amountFormat(giftCode.value) }}
                    </b-card-text>
                  </li>
                  <li>
                    <b-card-title class="title-info">
                      {{ $t('profile.giftCodeTab.textTimeOfUse') }}
                    </b-card-title>
                    <b-card-text class="content-info">
                      <feather-icon
                        icon="ClockIcon"
                        size="20"
                      />
                      {{ giftCode.timeOfUse }} {{ $t('profile.giftCodeTab.textDay') }}
                    </b-card-text>
                  </li>
                </ul>
              </div>
              <div class="required-gift-code">
                <b-card-title class="__title">
                  {{ $t('profile.giftCodeTab.textRequired') }}
                </b-card-title>
                <ul class="__content">
                  <li>{{ $t('profile.giftCodeTab.textReachTheMinimum', {minimumSpending: amountFormat(giftCode.minimumSpending)}) }}</li>
                  <li> {{ $t('profile.giftCodeTab.textSavedTheGift') }}</li>
                </ul>
              </div>

              <div class="detail-gift-code">
                <b-card-title class="__title">
                  {{ $t('profile.giftCodeTab.textDetail') }}
                </b-card-title>
                <b-card-text class="__content">
                  {{ $t('profile.giftCodeTab.contentOnceYouReachTheMinimumRequired', {timeOfUse: giftCode.timeOfUse, amountGiftCode: amountFormat(giftCode.value)}) }}
                </b-card-text>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <div class="btn-expand-controller">
          <div
            v-b-toggle="`collapse-${ index }`"
            class="btn-expand"
            @click="handleToggleExpand(index)"
          >
            <b-card-text
              :ref="`text-${index}`"
              class="content-btn-expand"
            >
              {{ $t('profile.giftCodeTab.textExpand') }}
            </b-card-text>
            <div
              class="ic-control"
            >
              <b-img
                :ref="`ic-${index}`"
                alt="img"
                class="ic-up"
                :src="require('@/assets/images/pages/giftCode/ic-arrow-down.svg')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-plusplus,no-underscore-dangle */
import { createNamespacedHelpers } from 'vuex'
import {
  BCardTitle,
  BCardText,
  BImg,
  BCollapse,
  BCard,
  VBToggle,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('giftCode')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('auth')

const UPDATE_FREQ_MS = 1000

export default {
  components: {
    BCardTitle,
    BCardText,
    BImg,
    BCollapse,
    BCard,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  mixins: [toastification],

  data() {
    return {
      isToggleExpand: false,

      timerCount: 0,
      indexExpand: null,
    }
  },

  computed: {
    ...mapGetters(['listGiftCodePartner', 'loading', 'status', 'message']),
    ...mapGettersUser(['user']),

    totalGiftCode() {
      return this.listGiftCodePartner?.paging?.total
    },

    availableQuantity() {
      return giftCode => giftCode.quantity - giftCode.savedQuantity
    },

    listGiftCode() {
      return this.listGiftCodePartner?.content
    },

    isNotAvailableGiftCode() {
      return this.listGiftCodePartner?.paging?.total === 0
    },

    amountFormat() {
      return amount => amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    expiredDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    isSavedGiftCode() {
      return giftCode => giftCode?.status === 'saved'
    },

    progressPercent() {
      return giftCode => (giftCode?.currentSpending / giftCode?.minimumSpending) * 100
    },

    currentSpending() {
      return giftCode => giftCode?.currentSpending
    },

    minimumSpending() {
      return giftCode => giftCode?.minimumSpending
    },

    isPaidGiftCode() {
      return giftCode => giftCode?.status === 'paid'
    },

    isFullRedeemed() {
      return giftCode => giftCode?.quantity === giftCode?.savedQuantity
    },
  },

  beforeUnmount() {
    clearInterval(this._updateTimer)
  },

  watch: {
    listGiftCodePartner: {
      handler(giftCodePartner) {
        clearInterval(this._updateTimer)
        if (giftCodePartner.content.length >= 1) {
          this._updateTimer = setInterval(() => this.timerCount++, UPDATE_FREQ_MS)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  // Vue 2.x equivalent of beforeUnmount
  beforeDestroy() {
    clearInterval(this._updateTimer)
  },

  methods: {
    ...mapActions([
      'redeemGiftCode',
      'getListGiftCodePartner',
      'getRedeemHistory',
    ]),

    // set class & content btn expand gift code detail
    handleToggleExpand(ref) {
      const ic = `ic-${ref}`
      const text = `text-${ref}`
      const valueRefIcon = this.$refs[ic][0].classList
      if (valueRefIcon.value === 'ic-up') {
        this.$refs[text][0].textContent = this.$t('profile.giftCodeTab.textCollapse')
        this.$refs[ic][0].classList = 'ic-down'
      } else {
        this.$refs[text][0].textContent = this.$t('profile.giftCodeTab.textExpand')
        this.$refs[ic][0].classList = 'ic-up'
      }
    },

    // event countdown time
    dayLeft(giftCode) {
      const { endedAt } = giftCode
      const diff = new Date(endedAt) - new Date()
      const twoD = x => `${Math.floor(x)}`.padStart(2, '0')
      const day = twoD((diff / (24 * 60 * 60 * 1000)))
      const hour = twoD((diff / (60 * 60 * 1000)) % 24)
      const minute = twoD((diff / (60 * 1000)) % 60)
      const seconds = twoD((diff / 1000) % 60)

      return this.$t('profile.giftCodeTab.textDayLeft', {
        day, hour, minute, seconds,
      })
    },

    // handle save gift code
    async handleSaveGift(giftCode) {
      const params = {
        code: giftCode.code,
      }

      await this.redeemGiftCode(params)
      if (this.status) {
        this.toastSuccess(this.$t('profile.giftCodeTab.toastSaveGiftSuccess'))
        this.getListGiftCodePartner({
          id: this.user?.data?.belongToPartner?.partnerId?._id,
          page: 1,
          size: 10,
        })
        this.getRedeemHistory({
          page: 1,
          size: 10,
        })
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.gift-code-container {
  border-radius: 32px;
  box-shadow: none;
  padding: 32px;
  margin-bottom: 2rem;

  .title-content {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .warning-message {
    font-size: 16px;
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 10px 12px 10px 16px;
  }

  .not-available-gift {
    margin-top: 32px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .gift-code-details-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 1rem;

    .gift-code-details {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .left-detail {
        padding: 14px 16px;
        width: 100%;

        &__top {
          display: flex;

          .gift-card, .gift-card-paid {
            border-radius: 10px;
            width: 134px;
            height: 76px;
            color: #fff;
            display: flex;

            .left-voucher {
              width: 20px;
              height: 100%;
              border-right: 1px dashed #fff;
            }

            .right-voucher {
              width: 114px;
              position: relative;

              p {
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              &:after, &:before {
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                background: #fff;
                border-radius: 100%;
              }

              &:before {
                top: -10px;
                left: -8px;
              }

              &:after {
                bottom: -10px;
                left: -8px;
              }
            }
          }

          .gift-card {
            background-color: #FF9903;
          }

          .gift-card-paid {
            background-color: #4CB375;
          }

          .info-gift-code {
            margin-left: 24px;

            .title-info {
              font-size: 16px;
            }

            .sub-info-gift-code {
              display: flex;
              margin-top: 12px;

              .info-limit {
                margin-right: 12px;
                background: #FF9903;
                border-radius: 2px;
                padding: 4px;
                color: #fff;

                .content-info {
                  font-size: 10px;
                  line-height: 1.45;
                  font-weight: bold;
                }

                .content-expired {
                  font-size: 14px;
                  line-height: 22px;
                }
              }
            }
          }
        }

        &__bottom {
          Width: 100%;
          margin-top: 20px;

          .percent-count {
            display: flex;
            margin-bottom: 8px;
            font-weight: bold;
            font-size: 14px;

            .title-percent {
              margin-right: 12px;
              margin-bottom: 0;
            }

            .content-percent-paid {
              color: #4CB375;
            }
          }

          .progress-bar {
            background: #e6eeff;
            height: 10px;
            border-radius: 100px;
            margin: auto;
            overflow: hidden;

            .progress {
              background-image: -webkit-linear-gradient(90deg, #FF0000 1.17%, #FF00E5 27.07%, #BD00FF 55.07%, #0029FF 74.7%, #00F0FF 95.31%);
              background-image: -moz-linear-gradient(90deg, #FF0000 1.17%, #FF00E5 27.07%, #BD00FF 55.07%, #0029FF 74.7%, #00F0FF 95.31%);
              background-image: -ms-linear-gradient(90deg, #FF0000 1.17%, #FF00E5 27.07%, #BD00FF 55.07%, #0029FF 74.7%, #00F0FF 95.31%);
              background-image: -o-linear-gradient(90deg, #FF0000 1.17%, #FF00E5 27.07%, #BD00FF 55.07%, #0029FF 74.7%, #00F0FF 95.31%);
              background-image: linear-gradient(90deg, #FF0000 1.17%, #FF00E5 27.07%, #BD00FF 55.07%, #0029FF 74.7%, #00F0FF 95.31%);
              width: 3px;
              height: 10px;
              border-radius: 5px;
            }

            .paid {
              background: #4CB375;
              width: 100%;
              height: 10px;
              border-radius: 5px;
            }
          }

        }
      }

      .right-detail {
        width: max-content;
        border-left: 1px solid #E6E6E6;
        padding: 0 24px;
        display: flex;
        align-items: center;

        &__loading {
          width: 92.516px;
          display: flex;
          justify-content: center;
        }

        .btn-save-gift, .btn-saved-gift, .btn-paid-gift, .btn-full-redeemed {
          width: max-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 92.516px;
          text-align: center;

          .__content{
            font-size: 14px;
            margin: 5px 0;
          }
        }

        .btn-full-redeemed{
          opacity: .5;
          cursor: no-drop;

          .text-red{
            color: #e11c4e

          }
        }

        .btn-paid-gift {
          width: 92.516px;
        }

        .btn-save-gift {
          cursor: pointer;
        }
      }
    }

    .collapse-gift-code {

      .info-detail-gift-code {
        padding: 16px;

        .title-info {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .content-info {
          display: flex;
          align-items: center;

          .feather {
            margin-right: 6px;
          }
        }

        .value-gift-code, .value-gift-code-paid {
          padding: 6px;
          border-radius: 6px;
        }

        .value-gift-code {
          color: #FF9903;
          border: 1px solid #FF9903;
        }

        .value-gift-code-paid {
          color: #4CB375;
          border: 1px solid #4CB375;
        }
      }

      .required-gift-code {
        margin-top: 25px;

        .__title {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .__content {
          font-size: 14px;
          list-style-type: inherit;
          margin-left: 1rem;
        }
      }

      .detail-gift-code {
        .__title {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .__content {
          font-size: 14px;
        }
      }

      .collapse-item {
        box-shadow: none;

        .card-body {
          padding: 0;
        }
      }
    }

    .btn-expand-controller {
      padding: 16px;

      .btn-expand {
        cursor: pointer;
        width: max-content;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .content-btn-expand {
          text-align: center;
          margin: 0;
          padding-right: 9.33px;
          color: #6E44FF;
          font-size: 14px;
          font-weight: bold;
        }

        .ic-control {
          display: flex;
        }

        .ic-down {
          transform: rotate(180deg)
        }

        .ic-up {
          transform: rotate(0)
        }
      }
    }
  }
}
</style>
