<template>
  <div>
    <!--    <b-button-->
    <!--      variant="flat-secondary"-->
    <!--      class="mb-1"-->
    <!--      to="/"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="ArrowLeftIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      Back to Home-->
    <!--    </b-button>-->

    <b-tabs
      v-if="$device.mobile"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      class="nav-profile"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab
        v-if="!hasStepGuide"
        :active="tab === 'business-information'"
        @click="handleUrlTab('business-information')"
      >
        <!-- title -->
        <template #title>
          <span class="font-medium">{{ $t("profile.textGeneral") }}</span>
        </template>

        <b-card class="card-content">
          <general />
        </b-card>
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab
        :v-if="!isFromTikTok"
        :active="tab === 'change-password'"
        @click="handleUrlTab('change-password')"
      >
        <template #title>
          <span class="font-medium">{{
            $t("profile.textChangePassword")
          }}</span>
        </template>

        <b-card><change-password /></b-card>
      </b-tab>
      <!--/ change password tab -->

      <!-- add gift code -->
      <b-tab
        v-if="isMember && !hasStepGuide"
        :active="tab === 'gift-code'"
        @click="handleUrlTab('gift-code')"
      >
        <template #title>
          <span class="font-medium">{{
            $t("profile.textRedeemGiftCode")
          }}</span>
        </template>

        <div>
          <redeem-gift-code />
        </div>
      </b-tab>
      <!--/ add gift code -->
    </b-tabs>
    <b-tabs
      v-else
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      class="nav-profile"
      end
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab
        v-if="!hasStepGuide"
        :active="tab === 'business-information'"
        @click="handleUrlTab('business-information')"
      >
        <!-- title -->
        <template #title>
          <span class="font-medium">{{ $t("profile.textGeneral") }}</span>
        </template>

        <b-card class="card-content">
          <general />
        </b-card>
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab
        v-if="!isFromTikTok"
        :active="tab === 'change-password'"
        @click="handleUrlTab('change-password')"
      >
        <template #title>
          <span class="font-medium">{{ $t("profile.textChangePassword") }}</span>
        </template>

        <b-card class="card-content">
          <change-password />
        </b-card>
      </b-tab>
      <!--/ change password tab -->

      <!-- add gift code -->
      <b-tab
        v-if="isMember && !hasStepGuide"
        :active="tab === 'gift-code'"
        @click="handleUrlTab('gift-code')"
      >
        <template #title>
          <span class="font-medium">{{
            $t("profile.textRedeemGiftCode")
          }}</span>
        </template>

        <div>
          <redeem-gift-code />
        </div>
      </b-tab>
      <!--/ add gift code -->

    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab, BCard,
  // BButton,
} from 'bootstrap-vue'
import partnerMixin from '@/mixins/partnerMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import envMixin from '@/mixins/envMixin'
import ChangePassword from './components/ChangePassword.vue'
import General from './components/General.vue'
import RedeemGiftCode from './components/RedeemGiftCode.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    // BButton,
    // components
    General,
    ChangePassword,
    RedeemGiftCode,
  },
  mixins: [partnerMixin, stepGuideMixin, envMixin],
  data() {
    return {
      tab: this.$route.query.tab || 'business-information',
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.tab = val?.query?.tab || 'business-information'
      },
    },
  },

  methods: {
    handleUrlTab(tab) {
      this.tab = tab
      this.$router.push(`profile?tab=${tab}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-back {
  margin-bottom: 24px;
  width: max-content;

  .card-text {
    cursor: pointer;

    .icon {
      margin-right: 15px;
    }
  }
}

.card-content {
  border-radius: var(--border-radius-lg);
  box-shadow: none;

  .card-body {
    padding: 32px;

    @media (max-width: 767px) {
      padding: 20px;
  }
  }
}
</style>

<style lang="scss">
.nav-profile {

  .nav-left{

    .nav-item{
      .nav-link{
        color: #16213EB2;
      }
      .nav-link.active{
        color: #16213E ;
        background: none;
        border-left: 3px solid #16213E;
        border-radius: 0;
      }
    }
  }
}
</style>
