<template>
  <b-modal
    ref="modal-gift-code-detail"
    id="gift-code-detail"
    class="gift-code-detail"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <h3 class="font-medium text-primary">
        {{ $t('profile.giftCodeTab.giftCodeDetailModal.titleModal') }}
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="position-relative pl-1 pr-1 reason-reject mt-1">
      <div class="info-detail-gift-code">
        <div>
          <ul class="d-flex justify-content-between">
            <li>
              <b-card-title class="title-info">
                {{ $t('profile.giftCodeTab.textRedeemCode') }}
              </b-card-title>
              <b-card-text class="content-info">
                {{ redeemCode(giftCodeData) }}
              </b-card-text>
            </li>
            <li>
              <b-card-title class="title-info">
                {{ $t('profile.giftCodeTab.textAvailableQuantity') }}
              </b-card-title>
              <b-card-text class="content-info">
                {{ availableQuantity(giftCodeData) }}
              </b-card-text>
            </li>
            <li>
              <b-card-title class="title-info">
                {{ $t('profile.giftCodeTab.textWorthGift') }}
              </b-card-title>
              <b-card-text class="content-info value-gift-code">
                {{ amountFormat(valueGiftCode(giftCodeData)) }}
              </b-card-text>
            </li>
            <li>
              <b-card-title class="title-info">
                {{ $t('profile.giftCodeTab.textTimeOfUse') }}
              </b-card-title>
              <b-card-text class="content-info">
                <feather-icon
                  icon="ClockIcon"
                  size="20"
                />
                {{ timeOfUse(giftCodeData) }} {{ $t('profile.giftCodeTab.textDay') }}
              </b-card-text>
            </li>
          </ul>
        </div>
        <div class="required-gift-code">
          <b-card-title class="__title">
            {{ $t('profile.giftCodeTab.textRequired') }}
          </b-card-title>
          <ul class="__content">
            <li>{{ $t('profile.giftCodeTab.textReachTheMinimum', {minimumSpending: amountFormat(minimumSpending(giftCodeData))}) }}</li>
            <li> {{ $t('profile.giftCodeTab.textSavedTheGift') }} </li>
          </ul>
        </div>

        <div class="detail-gift-code">
          <b-card-title class="__title">
            {{ $t('profile.giftCodeTab.textDetail') }}
          </b-card-title>
          <b-card-text class="__content">
            {{ $t('profile.giftCodeTab.contentOnceYouReachTheMinimumRequired', {timeOfUse: timeOfUse(giftCodeData), amountGiftCode: amountFormat(valueGiftCode(giftCodeData))}) }}
          </b-card-text>
        </div>
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        {{ $t('common.btnCancel') }}
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>

import {
  BModal,
  BButton,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
    BCardTitle,
    BCardText,
  },

  props: {
    giftCodeData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
    }
  },

  computed: {
    amountFormat() {
      return amount => amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    availableQuantity() {
      return giftCode => giftCode?.giftCodeId?.quantity - giftCode?.giftCodeId?.savedQuantity
    },

    redeemCode() {
      return gift => gift?.giftCodeId?.code
    },

    quantity() {
      return gift => gift?.giftCodeId?.quantity
    },

    valueGiftCode() {
      return gift => gift?.giftCodeId?.value
    },

    timeOfUse() {
      return gift => gift?.giftCodeId?.timeOfUse
    },

    minimumSpending() {
      return gift => gift?.giftCodeId?.minimumSpending
    },
  },

  methods: {

    hideModal() {
      this.$refs['modal-gift-code-detail'].hide()
    },

    showModal() {
      this.$refs['modal-gift-code-detail'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.info-detail-gift-code{

  .title-info{
    font-size: 14px;
    margin-bottom: 8px;
  }

  .content-info{
    display: flex;
    align-items: center;

    .feather{
      margin-right: 6px;
    }
  }

  .value-gift-code{
    color: #4CB375;
    padding: 6px;
    border: 1px solid #4CB375;
    border-radius: 6px;
  }
}

.required-gift-code{
  margin-top: 25px;

  .__title{
    font-size: 14px;
    margin-bottom: 8px;
  }

  .__content{
    font-size: 14px;
    list-style-type: inherit;
    margin-left: 1rem;
  }
}

.detail-gift-code{
  .__title{
    font-size: 14px;
    margin-bottom: 8px;
  }

  .__content{
    font-size: 14px;
  }
}
</style>
