<template>
  <div>
    <div>
      <list-gift-code />
    </div>

    <div class="bg-white card-content">
      <div class="gift-container">
        <div class="content-header">
          <h3>{{ $t('profile.giftCodeTab.titleRedeemCodeHistory') }}</h3>
          <!--          <div class="form-action pt-2">-->
          <!--            &lt;!&ndash; filter desktop &ndash;&gt;-->
          <!--            <div class="filter-desktop balance-allowcation">-->
          <!--              &lt;!&ndash; filter 1 &ndash;&gt;-->
          <!--              <b-row class="input-control mx-0 mb-2">-->
          <!--                <b-col-->
          <!--                  md="6"-->
          <!--                  class="px-0 input-height mx-0 mt-1 mt-md-0"-->
          <!--                >-->
          <!--                  <calendar-->
          <!--                    v-model="rangeDate"-->
          <!--                    @input="onSelect"-->
          <!--                  />-->
          <!--                </b-col>-->
          <!--              </b-row>-->
          <!--            </div>-->
          <!--            &lt;!&ndash; /filter desktop &ndash;&gt;-->
          <!--          </div>-->
        </div>
        <!-- table -->
        <vue-good-table
          class="vgt-no-border"
          mode="remote"
          :columns="columns"
          :rows="listRedeem"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: totalGiftCode > 0,
          }"
          :sort-options="{
            enabled: totalGiftCode > 0,
          }"
          :is-loading="loading"
          @on-sort-change="onSortChange"
        >
          <template
            slot-scope="props"
            slot="table-row"
          >
            <!-- Column: Ad Account -->
            <div
              v-if="props.column.field === 'giftCodeId.value'"
              class="d-flex flex-column"
            >
              <span class="worth-gift">{{ spendGiftCodeFormat(worthGift(props.row)) }}</span>
            </div>

            <!-- Column: giftCodeId.minimumSpending -->
            <div
              v-else-if="props.column.field === 'giftCodeId.minimumSpending'"
              class="d-flex flex-column"
            >
              <span>{{ spendGiftCodeFormat(minimumSpending(props.row)) }}</span>
            </div>

            <!-- Column: Status -->
            <div
              v-else-if="props.column.field === 'status'"
              class="d-flex align-items-center"
            >
              <div
                v-if="props.row.status"
                class="status-gift-code"
                :class="statusGiftCode(props.row.status)"
              >
                <span class="content-status">{{ statusGiftCodeTxt(props.row.status) }}</span>
              </div>
            </div>

            <!-- Column: giftCodeId.timeOfUse -->
            <div
              v-else-if="props.column.field === 'giftCodeId.timeOfUse'"
              class="d-flex align-items-center"
            >
              <div>
                <span>{{ timeOfUser(props.row) }}  {{ $t('profile.giftCodeTab.textDay') }}</span>
              </div>
            </div>

            <!-- Column: Status -->
            <span
              v-else-if="props.column.field === 'detail'"
              class="position-relative"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="text-body align-middle cursor-pointer icon-reason-block"
                @click="openGiftCodeDetail(props.row)"
              />

            </span>

            <!-- Column: requestedAt -->
            <!--            <span v-else-if="props.column.field === 'createdAt'">-->
            <!--              <div v-if="props.row.createdAt">-->
            <!--                {{ createdDate(props.row.createdAt) }}-->
            <!--              </div>-->
            <!--            </span>-->

            <!-- Column: common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            v-if="totalGiftCode > 0"
            slot="pagination-bottom"
          >
            <b-row
              class="d-flex flex-wrap footer-tab"
            >
              <b-col
                md="6"
                class="px-0 d-flex align-items-center"
              >
                <span
                  class="text-secondary font-14"
                >{{ totalGiftCode }}  {{ $t('profile.giftCodeTab.textGiftCode') }}</span>
              </b-col>
              <!-- page length -->
              <b-col
                v-if="totalGiftCode > 10"
                md="6"
                class="px-0"
              >
                <b-pagination
                  :total-rows="totalGiftCode"
                  :value="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 pagination-table"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </template>

          <!-- custom loading  -->
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              type="grow"
              small
            />
          </template>

          <div
            slot="emptystate"
            class="text-center"
          >
            <no-data-table-image class="my-5" />
          </div>
        </vue-good-table>
      </div>
    </div>

    <gift-code-detail-modal
      ref="open-modal-gift-code-detail"
      :gift-code-data="giftCodeDetail || {}"
    />
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  BRow,
  BCol,
  BSpinner,
  BPagination,
} from 'bootstrap-vue'
import { DATE_RANGE, STATUS_GIFT_CODE } from '@/constants'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import _get from 'lodash/get'

import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
// import Calendar from '@/components/Calendar.vue'
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'
import ListGiftCode from '@/views/profile/components/ListGiftCode.vue'
import GiftCodeDetailModal from '@/views/profile/components/GiftCodeDetailModal.vue'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('giftCode')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('auth')
export default {
  components: {
    GiftCodeDetailModal,
    ListGiftCode,
    BRow,
    BCol,
    BSpinner,
    VueGoodTable,
    NoDataTableImage,
    BPagination,
    // Calendar,
  },
  directives: {},

  mixins: [numberFormatMixin, toastification, stepGuideMixin, envMixin],
  data() {
    return {
      giftCodeDetail: {},
      code: '',
      columns: [
        {
          label: this.$t('profile.giftCodeTab.textCode'),
          field: 'giftCodeId.code',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('profile.giftCodeTab.textWorthGift'),
          field: 'giftCodeId.value',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('profile.giftCodeTab.textMinimumRequiredSpending'),
          field: 'giftCodeId.minimumSpending',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        // {
        //   label: 'Received Date',
        //   field: 'createdAt',
        //   thClass: 'p-1 border-0 header-table-mf',
        //   tdClass:
        //     'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        // },
        {
          label: this.$t('referral.tabPayouts.textStatus'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('profile.giftCodeTab.textTimeOfUse'),
          field: 'giftCodeId.timeOfUse',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('profile.giftCodeTab.textDetail'),
          field: 'detail',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
      currentPage: 1,
      searchTerm: '',

      // // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      pageGiftCodeLength: 5,

      // date picker
      // rangeDate: {
      //   startDate: null,
      //   endDate: null,
      // },

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
    }
  },

  computed: {
    ...mapGetters([
      'listHistoryRedeem',
      'listGiftCode',
      'message',
      'status',
      'loading',
      'listUserRedeemHistory',
    ]),
    ...mapGettersUser(['user']),

    worthGift() {
      return giftCode => giftCode?.giftCodeId?.value
    },

    minimumSpending() {
      return giftCode => giftCode?.giftCodeId?.minimumSpending || 0
    },

    timeOfUser() {
      return giftCode => giftCode?.giftCodeId?.timeOfUse || 0
    },

    spendGiftCodeFormat() {
      return amount => amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    listRedeem() {
      return _get(this.listUserRedeemHistory, ['content'], [])
    },

    totalGiftCode() {
      return _get(this.listUserRedeemHistory, ['paging', 'total'], [])
    },

    idPartner() {
      return _get(
        this.user,
        ['data', 'belongToPartner', 'partnerId', '_id'],
        '',
      )
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    // rangeDates() {
    //   return {
    //     from: this.rangeDate.startDate,
    //     to: this.rangeDate.endDate
    //       ? this.rangeDate.endDate
    //       : this.rangeDate.startDate,
    //   }
    // },

    statusGiftCode() {
      const statusColor = {
        [STATUS_GIFT_CODE.SAVED]: 'saved',
        [STATUS_GIFT_CODE.PAID]: 'paid',
        [STATUS_GIFT_CODE.EXPIRED]: 'expired',
        [STATUS_GIFT_CODE.ACTIVE]: 'active',
        [STATUS_GIFT_CODE.DEACTIVATE]: 'deactivate',
      }

      return status => statusColor[status]
    },

    statusGiftCodeTxt() {
      const statusGiftCode = {
        [STATUS_GIFT_CODE.SAVED]: this.$t('profile.giftCodeTab.statusSaved'),
        [STATUS_GIFT_CODE.PAID]: this.$t('billingPage.statusDone'),
        [STATUS_GIFT_CODE.EXPIRED]: this.$t('profile.giftCodeTab.statusExpired'),
        [STATUS_GIFT_CODE.ACTIVE]: this.$t('profile.giftCodeTab.statusActive'),
        [STATUS_GIFT_CODE.DEACTIVATE]: this.$t('profile.giftCodeTab.statusDeactivate'),
      }

      return status => statusGiftCode[status]
    },
  },

  created() {
    this.fetchListHistoryRedeem()
    const params = {
      id: this.user?.data?.belongToPartner?.partnerId?._id,
      page: 1,
      size: 10,
    }
    const paramsHistory = {
      page: 1,
      size: 10,
    }
    this.getListGiftCodePartner(params)
    this.getRedeemHistory(paramsHistory)
  },

  methods: {
    ...mapActions([
      'getListGiftCode',
      'getListGiftCodePartner',
      'getRedeemHistory',
    ]),

    openGiftCodeDetail(data) {
      this.giftCodeDetail = data
      this.$refs['open-modal-gift-code-detail'].showModal()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListHistoryRedeem()
    },

    // onSelect(val) {
    //   if (val) {
    //     this.fetchListHistoryRedeem()
    //   }
    // },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListHistoryRedeem()
    },

    resetForm() {
      this.code = ''
    },

    async fetchListHistoryRedeem() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        // ...this.rangeDates,
      }
      await this.getRedeemHistory(params)
    },

    async fetchListGiftCode(codeSearch) {
      const params = {
        id: this.idPartner,
        page: this.currentPage,
        size: this.pageGiftCodeLength,
        ...(codeSearch && { code: codeSearch }),
      }
      await this.getListGiftCode(params)
    },

    listGiftCodeScroll(val) {
      if (
        !this.loading
        && this.listGiftCode?.content.length < this.listGiftCode?.paging?.total
      ) {
        const params = {
          id: val,
          page: this.listGiftCode?.paging?.page + 1,
          size: this.pageGiftCodeLength,
          code: this.code,
        }
        this.getListGiftCode(params)
      }
    },

    async searchGiftCode(codeSearch) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }
      if (codeSearch === '') {
        this.clearSearch()
        this.fetchListGiftCode()
      } else if (codeSearch.length > 3) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchListGiftCode(codeSearch)
        }, 200)
      }
    },

    clearSearch() {
      this.code = ''
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
.card-content {
  border-radius: 32px;
  box-shadow: none;
}

.card-body {
  padding: 32px;
}

.content-header {
  border-radius: 32px;
  padding: 32px;

  @media(max-width: 767px) {
    padding: 32px 32px 32px 26px;
  }
}

.btn-content {
  margin-top: 8px;
  border-radius: 12px;
}

.footer-tab {
  padding: 27px 24px;
}

.status-gift-code {
  border-radius: 100px;
  padding: 6px 15.5px;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.done {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.paid {
    background: rgba(76, 179, 117, 0.1);
    color: #4CB375;
  }

  &.saved {
    background: rgba(255, 153, 3, 0.1);
    color: #FF9903;
  }

  &.expired {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.5);
  }

  &.deactivate {
    background: rgba(225, 28, 78, 0.1);
    color: #E11C4E;
  }

  &.active {
    background: rgba(29, 185, 195, 0.1);
    color: #1DB9C3;
  }
}

.worth-gift {
  padding: 6px 2px;
  border: 1px solid #4CB375;
  border-radius: 6px;
  color: #4CB375;
  width: max-content;
}
</style>
