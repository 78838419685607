<template>
  <div>
    <div class="title">
      <b-card-title
        title-tag="h1"
        class="title-text"
      >
        {{ $t('profile.titleChangePassword') }}
      </b-card-title>
    </div>
    <validation-observer
      ref="simpleRules"
      v-slot="{ invalid }"
    >
      <b-form
        @submit.prevent="validationForm"
      >
        <b-row>
          <!-- old password -->
          <b-col md="12">
            <b-form-group
              :label="$t('profile.labelOldPassword')"
            >
              <validation-provider
                #default="{ errors }"
                vid="OldPassword"
                rules="required"
                :name="$t('profile.labelOldPassword')"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model.trim="oldPasswordValue"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('profile.placeholderPassword')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconOld"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="12">
            <b-form-group
              :label="$t('profile.labelNewPassword')"
            >
              <validation-provider
                #default="{ errors }"
                vid="Password"
                rules="required|password"
                :name="$t('profile.labelNewPassword')"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model.trim="newPasswordValue"
                    id="account-new-password"
                    name="new-password"
                    trim
                    :type="passwordFieldTypeNew"
                    :placeholder="$t('profile.placeholderPassword')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="12">
            <b-form-group
              :label="$t('profile.labelRetypeNewPassword')"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|confirmed:Password"
                :name="$t('profile.labelRetypeNewPassword')"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model.trim="retypePasswordValue"
                    id="account-retype-new-password"
                    name="retype-password"
                    trim
                    :type="passwordFieldTypeRetype"
                    :placeholder="$t('profile.placeholderPassword')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconRetype"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <btn-loading
              pill
              type="submit"
              variant-convert="btn-submit"
              class="mt-1 mr-1 btn-save"
              :disabled="invalid"
              :loading="loading"
            >
              {{ $t('common.btnSaveChanges') }}
            </btn-loading>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend, BCardTitle,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BCardTitle,
    // components
    BtnLoading,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, envMixin],

  data() {
    return {
      oldPasswordValue: '',
      newPasswordValue: '',
      retypePasswordValue: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      required,
    }
  },
  computed: {
    ...mapGetters(['status', 'loading', 'message', 'error']),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  watch: {
    newPasswordValue(value) {
      this.newPasswordValue = this.removeSpace(value)
    },

    retypePasswordValue(value) {
      this.retypePasswordValue = this.removeSpace(value)
    },
  },

  methods: {
    ...mapActions(['updatePassword']),
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    removeSpace(str) {
      return str.replace(/\s/g, '')
    },

    async validationForm() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        const params = {
          currentPassword: this.oldPasswordValue,
          newPassword: this.newPasswordValue,
        }

        await this.updatePassword(params)

        if (this.status) {
          this.toastSuccess(this.$t('profile.textChangePasswordSuccess'))
          setTimeout(() => {
            this.$store.dispatch('auth/logout')
            if (this.loginUrl) {
              window.location.href = `${this.loginUrl}/logout`
            } else {
              this.$router.push({ name: 'auth-login' })
            }
          }, 1500)
        } else if (this.error?.data?.messageCode === 'error.invalid_current_password') {
          this.toastFailure(this.$t('profile.textCurrentPasswordWrong'))
        } else {
          this.toastFailure(this.$t('profile.textChangePasswordFailed'))
        }
      }
    },
  },
}
</script>
