var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title"},[_c('b-card-title',{staticClass:"title-text",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('profile.titleChangePassword'))+" ")])],1),_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.labelOldPassword')}},[_c('validation-provider',{attrs:{"vid":"OldPassword","rules":"required","name":_vm.$t('profile.labelOldPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"name":"old-password","type":_vm.passwordFieldTypeOld,"placeholder":_vm.$t('profile.placeholderPassword'),"state":errors.length > 0 ? false:null},model:{value:(_vm.oldPasswordValue),callback:function ($$v) {_vm.oldPasswordValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"oldPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.labelNewPassword')}},[_c('validation-provider',{attrs:{"vid":"Password","rules":"required|password","name":_vm.$t('profile.labelNewPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"account-new-password","name":"new-password","trim":"","type":_vm.passwordFieldTypeNew,"placeholder":_vm.$t('profile.placeholderPassword'),"state":errors.length > 0 ? false:null},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.labelRetypeNewPassword')}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:Password","name":_vm.$t('profile.labelRetypeNewPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","name":"retype-password","trim":"","type":_vm.passwordFieldTypeRetype,"placeholder":_vm.$t('profile.placeholderPassword'),"state":errors.length > 0 ? false:null},model:{value:(_vm.retypePasswordValue),callback:function ($$v) {_vm.retypePasswordValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"retypePasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('btn-loading',{staticClass:"mt-1 mr-1 btn-save",attrs:{"pill":"","type":"submit","variant-convert":"btn-submit","disabled":invalid,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('common.btnSaveChanges'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }