<template>
  <b-modal
    ref="modal-verify-phone"
    id="modal-verify-phone"
    centered
    title="Type your OTP"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    @close="onClose"
  >
    <div>
      <p>
        Enter the verification code we just sent to your phone number.
      </p>
      <validation-observer
        ref="formValidation"
        v-slot="{ invalid }"
      >
        <b-form
          class="form mt-2"
          @submit.prevent="submitForm"
        >
          <!-- OTP -->
          <b-form-group
            label="OTP"
            class="my-1 position-relative form-label"
          >
            <validation-provider
              #default="{ errors }"
              name="OTP"
              rules="required"
            >
              <b-input-group
                class=""
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.trim="verifyCode"
                  name="code"
                  class="input-height"
                  placeholder=""
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-flex justify-content-end mt-2">
            <btn-loading
              variant-convert="flat-secondary"
              pill
              :loading="loading"
              :disabled="countDown > 0"
              @click="resendCode"
            >
              Resend OTP
              <span v-if="countDown > 0">{{ `(${countDown}s)` }}</span>
            </btn-loading>
            <btn-loading
              class="ml-1"
              variant-convert="btn-submit"
              type="submit"
              pill
              :disabled="invalid"
              :loading="loading"
            >
              Submit
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, verifyCodeLength } from '@validations'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BModal,
    BtnLoading,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification],
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      verifyCode: '',
      countDown: 0,
      // validate
      required,
      verifyCodeLength,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  watch: {
    params(value) {
      if (value.ttl) {
        this.countDown = value.ttl
        this.countDownTimer()
      }
    },
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    onClose() {
      this.countDown = 0
    },

    showModal() {
      this.$refs['modal-verify-phone'].show()
    },

    hideModal() {
      this.$refs['modal-verify-phone'].hide()
    },

    resendCode() {
      this.$emit('resendCode', this.params.userData.contactInfo)
    },

    async submitForm() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        await this.updateAccount({
          ...this.params?.userData,
          verifyCode: this.verifyCode,
        })

        if (this.status) {
          this.toastSuccess(this.$t('profile.textUpdateAccountSuccess'))
          this.$refs['modal-verify-phone'].hide()
          await this.getAccountInfo()
          if (this.params.isFirstTime) {
            this.$router.push({ name: 'dashboard' })
          }
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.modal-footer-custom {
  border-top: none;
}

.input-custom {
  .input-group-text {
    background: rgba(22, 33, 62, 0.03) !important;
  }
}
</style>
